
import { ResponseError } from "@/app/entities/response.error";
import { watch, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  components: {},

  methods: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
    const ErrorEmailMessage1 = "El correo no es correcto";
    const ErrorEmailMessage2 =
      "Este correo tiene una cuenta activa, inicia sesión.";
    const emailUser = ref("");
    const emailError = ref(false);
    const checkTerms = ref(false);
    const checkError = ref(false);
    const emailErrorMessage = ref(ErrorEmailMessage1);

    const emailParsed = computed(() => emailUser.value.trim().toLowerCase());

    function checkForm() {
      if (!isEmailValid(emailParsed.value)) {
        emailError.value = true;
        return;
      }
      if (checkTerms.value == false) {
        checkError.value = true;
        return;
      }
      createAccountUser();
    }

    const createAccountUser = async () => {
      store
        .dispatch("userStore/createAccountUser", emailParsed.value)
        .then((userAccount) => {
          store.dispatch("userStore/updateUserData", userAccount);
          router.push({ name: "Checkout" });
        })
        .catch((error) => {
          setErrorCreateAccount(error);
        });
    };

    const setErrorCreateAccount = (error: ResponseError) => {
      if (error.message == "user_is_active") {
        emailError.value = true;
        emailErrorMessage.value = ErrorEmailMessage2;
      }
    };

    watch(emailUser, () => {
      emailError.value = false;
      emailErrorMessage.value = ErrorEmailMessage1;
    });
    watch(checkTerms, () => (checkError.value = false));

    function isEmailValid(email: string) {
      return reg.test(email) ? true : false;
    }

    return {
      emailUser,
      checkTerms,
      emailError,
      checkForm,
      checkError,
      emailErrorMessage,
    };
  },
};
